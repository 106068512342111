export const REACT_APP_API_LOCATION = (() => {
  switch (window.location.hostname) {
    case 'demo1.fretimmerman.eu':
      return 'https://demo1api.fretimmerman.eu';
    case 'localhost':
      return 'http://localhost:1337';
    case '192.168.0.114':
      return 'http://192.168.0.114:1337';
    default:
      return 'https://dvbapi.hugswithtails.be';
  }
})();
export const REACT_APP_API_ADMIN_LOCATION = (() => {
  switch (window.location.hostname) {
    case 'demo1.fretimmerman.eu':
      return 'https://demo1api.fretimmerman.eu/admin';
    case 'localhost':
      return 'http://localhost:1337/admin';
    case '192.168.0.114':
      return 'http://192.168.0.114:1337/admin';
    default:
      return 'https://dvbapi.hugswithtails.be/admin';
  }
})();
export const LOGIN_IMG_URL = (() => {
  switch (window.location.hostname) {
    case 'demo1.fretimmerman.eu':
      return 'img/demo.png';
    default:
      return 'img/logo.png';
  }
})();
export const DEBUG_SKIP_LEDEN = (() => {
  switch (window.location.hostname) {
    case 'localhost':
    case '192.168.0.114':
      return true;
    default:
      return false;
  }
})();
