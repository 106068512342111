import { useEffect, useState } from 'react';
const LOCATIONFILTER = 'LOCATIONFILTER';

export const useLocations = () => {
  const [locationToFilter, setLocationToFilter] = useState(
    JSON.parse(localStorage.getItem(LOCATIONFILTER) ?? 0),
  );

  const [userLocations, setUserLocations] = useState([]);

  const changeLocation = (newLocId) => {
    setLocationToFilter(newLocId);
    localStorage.setItem(LOCATIONFILTER, parseInt(newLocId, 10));
  };

  useEffect(() => {
    if (localStorage.getItem(LOCATIONFILTER) === null) {
      changeLocation(0);
    }
  }, [locationToFilter]);

  return { locationToFilter, changeLocation, setUserLocations, userLocations };
};

export const useStorage = (
  key,
  defaultValue = '_default',
  selectedStorage = 'local',
) => {
  const storage = selectedStorage === 'local' ? localStorage : sessionStorage;
  const [filter, _setFilter] = useState(
    JSON.parse(storage.getItem(key)) ?? defaultValue,
  );

  const setFilter = (newValue) => {
    _setFilter(newValue);
    storage.setItem(key, JSON.stringify(newValue));
  };

  useEffect(() => {
    if (storage.getItem(key) === null) {
      setFilter(defaultValue);
    }
  }, [key, defaultValue]);

  return [filter, setFilter];
};
