import React from 'react';
import { Form, useFormikContext } from 'formik';
import styled from 'styled-components';

import Button from '../common/Button';
import SimpleTextInput from '../common/input/simpleTextInput';

const Grid = styled.div`
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 2fr 2fr;
  gap: 1px;
  background-color: #404040;
  border: 1px solid #404040;
  & > * {
    background-color: #fff;
  }
`;

const Cell = styled.div`
  ${({ col }) => (col ? `grid-column:${col}` : '')};
  ${({ row }) => (row ? `grid-row:${row}` : '')};

  display: grid;
  place-items: center;
  padding: 0.25rem 0.5rem;
`;

const InputCell = styled(SimpleTextInput)`
  ${({ col }) => (col ? `grid-column:${col}` : '')};
  ${({ row }) => (row ? `grid-row:${row}` : '')};
  & input {
    padding: 0 0.5rem;

    @media (max-width: 560px) {
      padding: 0 2px;
    }
  }
`;

const MultiLineCell = styled(Cell)`
  line-height: 1.5rem;
  text-align: center;
`;

const AfsluitenContainer = styled.div.attrs({ 'data-color-mode': 'light' })`
  min-width: 80%;

  @media (max-width: 560px) {
    font-size: 0.75rem;
  }
`;

export const Inventaris = ({ loading }) => {
  const { submitForm, isValid, errors, touched, values } = useFormikContext();
  console.log('hello');
  console.log('values', values);
  const isEveryErrorFieldTouched = React.useMemo(
    () => Object.keys(errors).every((key) => touched.hasOwnProperty(key)),
    [errors, touched],
  );

  return (
    <Form>
      <AfsluitenContainer>
        <Grid>
          <Cell col="1/2">Datum</Cell>
          <InputCell name="datum" col="2/6" />
          <Cell col="1/2">Verantwoordelijke</Cell>
          <InputCell name="verantwoordelijke" col="2/4" />
          <Cell col="4/5">Medewerker</Cell>
          <InputCell name="medewerker" col="5/6" />
        </Grid>
        <Grid>
          <Cell col="1/4">Categorie</Cell>
          <Cell col="4/6">Aantal voorradig</Cell>
          <Cell row="2/11" col="1/2">
            Hond
          </Cell>
          <Cell row="2/5" col="2/3">
            Puppy
          </Cell>
          <Cell col="3/4">Klein</Cell>
          <InputCell name="kleinPuppy" col="4/6" />
          <Cell col="3/4">Medium</Cell>
          <InputCell name="mediumPuppy" col="4/6" />
          <Cell col="3/4">Groot</Cell>
          <InputCell name="grootPuppy" col="4/6" />
          <Cell row="5/8" col="2/3">
            Volwassen
          </Cell>
          <Cell col="3/4">Klein</Cell>
          <InputCell name="kleinVolwassen" col="4/6" />
          <Cell col="3/4">Medium</Cell>
          <InputCell name="mediumVolwassen" col="4/6" />
          <Cell col="3/4">Groot</Cell>
          <InputCell name="grootVolwassen" col="4/6" />
          <Cell row="8/11" col="2/3">
            Senior
          </Cell>
          <Cell col="3/4">Klein</Cell>
          <InputCell name="kleinSenior" col="4/6" />
          <Cell col="3/4">Medium</Cell>
          <InputCell name="mediumSenior" col="4/6" />
          <Cell col="3/4">Groot</Cell>
          <InputCell name="grootSenior" col="4/6" />
          <Cell row="11/14">Kat</Cell>
          <Cell col="2/4">Kitten</Cell>
          <InputCell name="kitten" col="4/6" />
          <Cell col="2/4">Volwassen</Cell>
          <InputCell name="volwassenKat" col="4/6" />
          <Cell col="2/4">Senior</Cell>
          <InputCell name="seniorKat" col="4/6" />
          <Cell col="1/4">Kattenzand</Cell>
          <InputCell name="kattenzand" col="4/6" />
        </Grid>
        <Grid>
          <MultiLineCell col="1/2">
            Speciallekes
            <br />
            (voeding op naam)
            <br />
            Namen niet afgehaald:
          </MultiLineCell>
          <InputCell name="speciallekes" col="2/6" multiline />
        </Grid>
        <Grid>
          <MultiLineCell col="1/2">
            Opmerkingen: vb:
            <br />
            &quot;vers voer is op&quot;,
            <br />
            &quot;konijnenvoer aanvullen&quot;,
            <br />
            &quot;kakzakjes bijna op&quot;,
            <br />
            &quot;kattenbak gevraagd voor mevr. X&quot;,
            <br />
            etc.
          </MultiLineCell>
          <InputCell name="opmerkingen" col="2/6" multiline rows={15} />
        </Grid>
      </AfsluitenContainer>
      <Button
        primary
        disabled={!isValid && isEveryErrorFieldTouched}
        disabledReason="Er zijn fouten in het formulier, kijk alle velden na"
        onClick={submitForm}
        loading={loading}>
        OK, stuur naar Vulploeg
      </Button>
    </Form>
  );
};
