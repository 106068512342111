import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { isFuture, isPast, isToday } from 'date-fns';
import styled from 'styled-components';

import { GET_BEDELINGEN, GET_LEDEN } from '../graphql/index.js';
import LoadingDiv from './common/LoadingDivModal';
import Page from './common/page.js';
import TopTitle from './common/TopTitle.js';
import LocatieFilter from './LocatieFilter';

const TitleAndFilter = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 600px) {
    flex-direction: row;

    justify-content: space-between;
  }
`;

const ColumNames = styled.div`
  grid-template-columns: repeat(
    ${({ showLocation }) => (showLocation ? '5' : '4')},
    1fr
  );
  flex-grow: 1;
  display: grid;
`;

const ColumnHeaders = ({ showLocation }) => {
  return (
    <div className="table-row header">
      <ColumNames>
        {showLocation && <div className="column location">Locatie</div>}
        <div className="column date">Datum</div>
        <div className="column amount-expected"># actief</div>
        <div className="column amount-came"># gekomen</div>
        <div className="column percent-came">%</div>
      </ColumNames>
    </div>
  );
};

const BedelingenTable = ({
  leden,
  title,
  bedelingen,
  showNext,
  showLocation,
}) => {
  if (!showNext) {
    bedelingen = bedelingen.reverse();
  }

  const mappedBedelingen = bedelingen?.map((bed) => {
    const expected =
      bed.aantalActief === 0
        ? 0
        : bed.aantalActief ||
          leden?.filter(
            (lid) => lid?.actief && bed.locatie.id === lid.locatie.id,
          ).length;
    const amountCame = bed.aanwezigheden?.filter((x) => x?.aanwezig).length;
    const eventdate = new Date(bed.datum);
    const percent =
      amountCame > 0 ? Math.round((amountCame / expected) * 100) + '%' : '/';
    const viewURL = '/bedelingen/view/' + bed.id;
    const dateOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };
    return (
      <Link to={viewURL} className="table-row" key={'b' + bed.id}>
        <ColumNames>
          {showLocation && (
            <div className="column location">{bed.locatie.Naam}</div>
          )}
          <div className="column date">
            {eventdate.toLocaleDateString('nl-BE', dateOptions)}
          </div>
          <div className="column amount-expected">
            {`${expected}${bed.aantalActief !== null ? '' : ' verwacht'}`}
          </div>
          <div className="column amount-came">{amountCame}</div>
          <div className="column percent-came">{percent}</div>
        </ColumNames>
      </Link>
    );
  });
  if (bedelingen.length) {
    return (
      <div>
        <div className="bedelingRubriekTitle">{title}</div>
        <ColumnHeaders showLocation={showLocation} />
        {mappedBedelingen}
      </div>
    );
  } else {
    return (
      <div>
        <div className="bedelingRubriekTitle">{title}</div>
        <div className="noContent">geen bedeling voor {title}</div>
      </div>
    );
  }
};

const Bedelingen = ({
  locations: { locationToFilter, changeLocation, userLocations },
}) => {
  const [leden, setLeden] = React.useState([]);
  const [bedelingen, setBedelingen] = React.useState([]);

  const { loading: loadingLeden } = useQuery(GET_LEDEN, {
    pollInterval: 5000,
    onCompleted: (dataLeden) => {
      const ledenBackup = dataLeden?.ledens;
      setLeden(
        locationToFilter && userLocations?.length !== 1
          ? ledenBackup?.filter((x) => x.locatie.id == locationToFilter) // == by design
          : ledenBackup?.slice(),
      );
    },
  });
  const { loading: loadingBedelingen } = useQuery(GET_BEDELINGEN, {
    pollInterval: 5000,
    onCompleted: (dataBedelingen) => {
      const bedelingenBackup = dataBedelingen?.bedelingens;
      setBedelingen(
        locationToFilter && userLocations?.length !== 1
          ? bedelingenBackup?.filter((x) => x.locatie.id == locationToFilter) // == by design
          : bedelingenBackup?.slice(),
      );
    },
  });

  const bedelingVandaag = bedelingen?.filter((x) => isToday(new Date(x.datum)));
  const bedelingenToekomst = bedelingen?.filter(
    (x) => !isToday(new Date(x.datum)) && isFuture(new Date(x.datum)),
  );
  bedelingenToekomst?.sort((a, b) => new Date(a.datum) - new Date(b.datum));
  const bedelingenVerleden = bedelingen?.filter(
    (x) => !isToday(new Date(x.datum)) && isPast(new Date(x.datum)),
  );
  bedelingenVerleden?.sort((a, b) => new Date(a.datum) - new Date(b.datum));

  return (
    <Page>
      <TitleAndFilter>
        <TopTitle>BEDELINGEN</TopTitle>
        <LocatieFilter
          userLocations={userLocations}
          locationToFilter={locationToFilter}
          changeLocation={changeLocation}
        />
      </TitleAndFilter>
      <div className="container-fluid bedelingen" id="table_container">
        {(loadingLeden || loadingBedelingen) && <LoadingDiv />}
        {bedelingen?.length === 0 && <div>Geen bedelingen</div>}
        {bedelingen && bedelingen.length > 0 && (
          <BedelingenTable
            title="Vandaag"
            bedelingen={bedelingVandaag}
            showNext={false}
            showLocation={userLocations?.length > 1}
            leden={leden}
          />
        )}
        {bedelingen && bedelingen.length > 0 && (
          <BedelingenTable
            title="Toekomst"
            bedelingen={bedelingenToekomst}
            showNext
            showLocation={userLocations?.length > 1}
            leden={leden}
          />
        )}
        {bedelingen && bedelingen.length > 0 && (
          <BedelingenTable
            title="Verleden"
            bedelingen={bedelingenVerleden}
            showNext={false}
            showLocation={userLocations?.length > 1}
            leden={leden}
          />
        )}
      </div>
    </Page>
  );
};
export default Bedelingen;
